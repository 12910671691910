import * as React from "react";
import { useState } from "react";
import { apiUrl } from "./axios";
import axios from "./axios";
import { Button } from "react-bootstrap";
import { useUser } from "./UserContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ForgetPassword from "./ForgetPassword";

function Copyright(props) {
  return (
    <p align="center" {...props}>
      {"Copyright © "}
      <a color="inherit">
        Masys
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </p>
  );
}

function validateForm(values) {
  const errors = {};

  if (!values.Username) {
    errors.Username = "Enter Username";
  }

  if (!values.Password) {
    errors.Password = "Enter Password";
  }

  return errors;
}

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const closed = () => {
    setIsModal(false);
  };

  const open = () => {
    setIsModal(true);
  };



  const [values, setValues] = useState({
    Username: "",
    Password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { setData } = useUser();
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });
  const [loginError, setLoginError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const formErrors = validateForm(values);
    setErrors(formErrors);


    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      axios
        .post(`/login`, values)
        .then((res) => {
          if (res.data.Success === "Success") {
         //console.log(res.data)
              navigate("/");
            setLoading(false);
          } else {
            setLoginError("Fail to Sign In. Please check your Credentials.");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoginError("An error occurred during Sign In.");
        });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner py-4">
          {/* <!-- Login --> */}
          <div class="card">
            <div class="card-body">
              {/* <!-- Logo --> */}
              <div class="app-brand justify-content-center mb-2 mt-2">
                  <span class="demo">
                  <img
                id="logoimg"
                className="brand-logo border-1"
                src={`/assets/img/logo1.jpeg`}
                style={{ width: '150px' }}
              />
                  </span>
              </div>
              {/* <!-- /Logo --> */}
              <h4 class="mb-1 pt-2 mb-3 text-center">Sign In</h4>
              {loginError && (
                         <p
                           variant="body2"
                           className="text-danger"
                           style={{ textAlign: "center", marginTop: "10px" }}
                         >
                           {loginError}
                         </p>
                       )}

                       <form className="" onSubmit={handleSubmit}>
                         <div className="mb-3">

                           <input
                             className="form-control"
                             type="text"
                             name="Username"
                             id="Username"
                             placeholder="Enter Username"
                             onChange={(e) =>
                               setValues({ ...values, Username: e.target.value })
                             }
                           />
                           <span style={{ color: "Red" }}>{errors.Username}</span>
                         </div>
                         <div className="mb-3">
                           <div className="input-group">
                             <input
                               className="form-control"
                               type={showPassword ? "text" : "password"}
                               name="password"
                               id="password"
                               placeholder="Enter Password"
                               autoComplete="current-password"
                               onChange={(e) =>
                                 setValues({ ...values, Password: e.target.value })
                               }
                             />
                             <span
                               className=" input-group-text"
                               itemType="button"
                               style={{ cursor: "pointer" }}
                               onClick={handleTogglePasswordVisibility}
                             >
                               {showPassword ? (
                                <i class="ti ti-eye-off"></i>
                               ) : (
                                <i class="ti ti-eye"></i>
                               )}
                             </span>
                           </div>
                           <span style={{ color: "Red" }}>{errors.Password}</span>
                         </div>

                         <div className="row p-3">
                           {!isLoading && (
                             <Button
                               className="btn btn-primary"
                               type="submit"

                             >
                               Sign In
                             </Button>
                           )}
                           {isLoading && (
                             <Button
                               className="btn btn-login"
                               type="submit"

                               disabled
                             >
                               <span className="spinner-border spinner-border-sm" style={{ width: '1rem', height: '1rem' }} role="status" aria-hidden="true"></span>

                               {"  "} Sign In...
                             </Button>
                           )}
                         </div>
                         <div className="link-container text-center">
        <Link className="link" to="/enquiry">
          Don't have an account? Sign up here..
        </Link>
        <br/>
        <Link className="link"  onClick={()=>{open()}}>
        Forgot Password?
        </Link>
      </div>
                         {/* <Copyright style={{ marginTop: "20px" }} /> */}
                       </form>
            </div>
          </div>
          {/* <!-- /Register --> */}
        </div>
        {isModal && (
          <>
            <ForgetPassword
              open={isModal}
              onClose={closed}
            />
          </>
        )
        }
      </div>
    </div>
  );
};

export default Login;
