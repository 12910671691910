import * as React from "react";
import Select from "react-select";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import { formatDate } from '../../components/dateFormate'
import { AlignCenter } from "react-bootstrap-icons";
import dayjs from "dayjs";



const ViewAllInvoices = () => {
  const { userData } = useUser();
  
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
const [filter, setFilter] = useState({
  Customer_Id: "",
  Company_Name: "",
  fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
  toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
});
useEffect(() => {
  if (userData && userData.Org_Id) {
    loadStaffData();
  }
}, [userData]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      
      const result = await axios.get(
        `/getAllInvoice?Org_Id=${userData.Org_Id}`
      );
      setStaffData(result.data || []);
      console.log(result.data[0]);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const uniqueCompanyNames = [
    { label: "All", value: "" }, // Add "All" option
    ...[...new Set(dataa.map((row) => row.Company_Name))].map((name) => ({
      label: name,
      value: name,
    })),
  ];

  const handleFilterChange = (selectedOption) => {
    setFilter((prev) => ({
      ...prev,
      Company_Name: selectedOption ? selectedOption.value : "",
    }));
  };

  const applyFilter = () => {
    const { Company_Name, fromDate, toDate } = filter;
    const filteredData = dataa.filter((row) => {
      const isCompanyMatch = Company_Name
        ? row.Company_Name.toLowerCase().includes(Company_Name.toLowerCase())
        : true;
      const isDateInRange =
        (!fromDate || new Date(row.Invoice_Date) >= new Date(fromDate)) &&
        (!toDate || new Date(row.Invoice_Date) <= new Date(toDate));
      return isCompanyMatch && isDateInRange;
    });
    setStaffData(filteredData);
  };


  const columns = [
    {
      name: "Invoice No.",
      cell: (row) => (
        <Link to={`/invoice/${row.Enq_Id}/Facility`} target="_blank">
          {row.Invoice_No}
        </Link>
      ),
      width: "100px",
    },
    {
      name: "Invoice Date",
      cell: (row) => formatDate(row.Invoice_Date),
      width: "100px",
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "220px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name}

          </span>
        </LightTooltip>
      ),
      width: "290px",
    },
    { name: "Name", selector: (row) => row.Customer_Name },
    { name: "Remarks", selector: (row) => row.Remarks },
    { name: "Certificate", selector: (row) => row.Certificate_Name },
    { name: "Net Amt.", selector: (row) => row.Net_Amount, width: "90px" }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
        orientation: 'landscape'
    });
    doc.text("Invoice Details", 20, 10);

    // Updated columns to include new fields
    const columns = [
        "Invoice No.",
        "Invoice Date",
        "Enquiry ID",
        "Company Name",
        "Customer Name",
        "Certificate Name",
        "Application No.",
        "Application Date",
        "OTP Mobile Number",
        "Username",
        "Password",
        "Link",
        "State",
        "GST No.",
        "Government Charges",
        "Consultation Charges",
        "GST Amount",
        "Total Consultation",
        "Net Amount", 
        "Remarks",
        "Refereences",
        "Other References"
    ];

    // Update rows to include new fields from data
    const rows = data.map((row) => [
        row.Invoice_No,
        formatDate(row.Invoice_Date),
        row.Enquiry_Id,
        row.Company_Name,
        row.Customer_Name,
        row.Certificate_Name,
        row.Application_No,         
        formatDate(row.Application_Date),
        row.Username,           
        row.Password,
        row.Mobile_Number,      
        row.Link,               
        row.State,                  
        row.GST_No,                 
        row.Gvt_Amount,
        row.Consultation_Charges,
        row.Gst_Amount,
        row.Total_Consultation,
        row.Net_Amount,
        row.Remarks,
        row.Reference,
        row.Other_Reference
    ]);

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    doc.save("InvoiceDetails.pdf");
};


  const DownloadExcel = () => {
    const columns = [
        "Invoice No.",
        "Invoice Date",
        "Enquiry ID",
        "Company Name",
        "Customer Name",
        "Certificate Name",
        "Application No.",
        "Application Date",
        "OTP Mobile Number",
        "Username",
        "Password",
        "Link",
        "State",
        "GST No.",
        "Government Charges",
        "Consultation Charges",
        "GST Amount",
        "Total Consultation",
        "Net Amount", 
        "Remarks",
        "References",
        "Other References"
    ];

    const rows = [columns];
    data.forEach((row) => {
        rows.push([
          row.Invoice_No,
          formatDate(row.Invoice_Date),
          row.Enquiry_Id,
          row.Company_Name,
          row.Customer_Name,
          row.Certificate_Name,
          row.Application_No,         
          formatDate(row.Application_Date),
          row.Username,           
          row.Password,
          row.Mobile_Number,      
          row.Link,               
          row.State,                  
          row.GST_No,                 
          row.Gvt_Amount,
          row.Consultation_Charges,
          row.Gst_Amount,
          row.Total_Consultation,
          row.Net_Amount,
          row.Remarks,
          row.Reference,
          row.Other_Reference
        ]);
    });

    console.log("excel " + rows);
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Invoice Details");

    // Download the Excel file
    XLSX.writeFile(workBook, "InvoiceDetails.xlsx");
};




  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Role && row.Role.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Invoice Details</h4>
          </div>
          <div className="card">
            
          <div className="card-body" style={{paddingTop:"15px"}}>
              <div className="row justify-content-center mt-2">
                <div className="col-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <Select
                    id="companyName"
                    options={uniqueCompanyNames}
                    value={
                      filter.Company_Name
                        ? { label: filter.Company_Name, value: filter.Company_Name }
                        : null
                    }
                    onChange={handleFilterChange}
                    placeholder="Select Company"
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="fromDate" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fromDate"
                    name="fromDate"
                    value={filter.fromDate}
                    onChange={(e) => setFilter({ ...filter, fromDate: e.target.value })}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="toDate" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="toDate"
                    name="toDate"
                    value={filter.toDate}
                    onChange={(e) => setFilter({ ...filter, toDate: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-2">
                <button
                  className="btn btn-sm btn-primary"
                  style={{ width: "100%" }}
                  onClick={applyFilter}
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="card-body">
          
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default ViewAllInvoices;
