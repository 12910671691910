import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";

const UploadCertificate = ({ open, onClose, Modaldata }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, seterrors] = useState({});
  const navigate = useNavigate();

  console.log(Modaldata);

  const [upload, setUpload] = useState({
    Upload_Certificate: "",
    Certificate_Number:"",
    Certificate_Date:"",
    Renewal_Date:"",
    Financial_Year:""

  });

  console.log(upload);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const UploadCertificateHandle = async (e) => {
    e.preventDefault();
    debugger;

    const data = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: `${Modaldata.First_Name} ${Modaldata.Last_Name}`,
      Certificate_Name: Modaldata.Certificate_Name,
    };
    console.log("User Data :",data);


    // Construct FormData object and append data
    const formdata = new FormData();
    formdata.append("Upload_Certificate", upload.Upload_Certificate);
    formdata.append("Certificate_Number", upload.Certificate_Number);
    formdata.append("Certificate_Date", upload.Certificate_Date);
    formdata.append("Renewal_Date", upload.Renewal_Date);
    formdata.append("Financial_Year", upload.Financial_Year);
    formdata.append("User_Role", userData.User_Role);
    formdata.append("UserName", userData.UserName);
    formdata.append("Added_By", userData.Added_By);
    formdata.append("Name", userData.Name);
    formdata.append("Certificate_Name", userData.Certificate_Name);


    Object.entries(data).forEach(([key, value]) => {
      formdata.append(key, value);
      
    });
  
    

    // Show loader while uploading
    showLoader();

    // Send PUT request
    axios
      .put(`/updateCustomerCertificate/${Modaldata?.Enquiry_Sr_No}`, formdata)
      .then((res) => {
        Swal.fire("Success!", "Certificate Uploaded Successfully.", "success");
        handleClose();
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Upload. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      });
  };

  

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Body>
        <h5>{`${Modaldata.First_Name} ${Modaldata.Last_Name} - ${Modaldata.Certificate_Name}`}</h5>
        <hr className="p-0 mb-1" />
        <form onSubmit={UploadCertificateHandle}>
        <div className="row mt-3">
    <div className="col-md-4">
      <label htmlFor="Certificate_Number" style={{color:"#00000082"}}>Certificate Number</label>
      <input
        type="text"
        id="Certificate_Number"
        name="Certificate_Number"
        className="form-control"
        placeholder="Upload Certificate Number..."
        value={upload.Certificate_Number || ''} // Assuming you have a state for this
        onChange={(e) => setUpload({ ...upload, Certificate_Number: e.target.value })}
        required // Optional, based on your validation needs
      />
    </div>

    <div className="col-md-4">
      <label htmlFor="Certificate_Date"  style={{color:"#00000082"}}>Certificate Date</label>
      <input
        type="date"
        id="Certificate_Date"
        name="Certificate_Date"
        className="form-control"
        value={upload.Certificate_Date || ''} // Assuming you have a state for this
        onChange={(e) => setUpload({ ...upload, Certificate_Date: e.target.value })}
        required // Optional, based on your validation needs
      />
    </div>

    <div className="col-md-4">
      <label htmlFor="Renewal_Date"  style={{color:"#00000082"}}>Renewal Date</label>
      <input
        type="date"
        id="Renewal_Date"
        name="Renewal_Date"
        className="form-control"
        value={upload.Renewal_Date || ''} // Assuming you have a state for this
        onChange={(e) => setUpload({ ...upload, Renewal_Date: e.target.value })}
        required // Optional, based on your validation needs
      />
    </div>
   
  </div>
  <div className="row">
          
  <div className="col-md-4">
      <label htmlFor="Financial_Year"  style={{color:"#00000082"}}>Financial Year</label>
      <input
        type="test"
        id="Financial_Year"
        name="Financial_Year"
        className="form-control"
        placeholder="Enter Financial Year..."
        value={upload.Financial_Year || ''} // Assuming you have a state for this
        onChange={(e) => setUpload({ ...upload, Financial_Year: e.target.value })}
        required // Optional, based on your validation needs
      />
    </div>
    <div className="col-md-6">
      <label htmlFor="Upload_Certificate" style={{color:"#00000082"}}>Upload Certificate</label>
      <input
        type="file"
        id="Upload_Certificate"
        name="Upload_Certificate"
        accept=".jpg, .jpeg, .png, .pdf"
        capture="false"
        className="form-control"
        onChange={(e) => {
          setUpload({
            ...upload,
            Upload_Certificate: e.target.files[0],
          });
        }}
      />
    </div>
    <div className="col-md-2 mt-4">
      {Modaldata?.Upload_Certificate ? (
        <>
          <Link
            style={{ color: "blue", cursor: "pointer" }}
            to={`${apiUrl}/viewCertificate/${Modaldata.Upload_Certificate}`}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
          >
            <i className="ti ti-eye"></i>
          </Link>
          {" / "}
          <Link
            style={{ color: "blue", cursor: "pointer" }}
            to={`${apiUrl}/downloadCetificate/${Modaldata.Upload_Certificate}`}
            download={true}
            className="cursor-pointer"
          >
            <i className="ti ti-download"></i>
          </Link>
        </>
      ) : (
        <>
          <i className="ti ti-eye-off"></i> /{" "}
          <i className="ti ti-download-off"></i>
        </>
      )}
    </div>

  </div>

  {/* New Fields for Certificate Number, Date, and Renewal Date */}


  <div className="row justify-content-center mt-4">
    <div className="col-md-2 col-sm-3 col-xs-3 col-3">
      <button type="submit" className="btn btn-md btn-primary ">
        Upload
      </button>
    </div>
  </div>
</form>


        {loader}
      </Modal.Body>
    </Modal>
  );
};
export default UploadCertificate;
