import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UploadAcknowledgement = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  console.log(updateData);

  const [upload, setUpload] = useState({
    Message_Body: "",
    Attachment1: "",
    Attachment2: "",
    Attachment3: "",
    Application_Date: "",
    Application_Number: "",
    Username: "",
    Password: "",
    Link: "",
    Mobile_Number:""

  });

  // useEffect(() => {
  //     loadData();
  // }, [updateData]);

  // const loadData = async () => {
  //     showLoader();
  //     try {
  //       await axios.get(`/getEnquiry/${updateData}`).then((res) => {

  //             console.log(res.data)
  //             const data = res.data[0];
  //             setUpload({
  //                 Message_Body: data.Message_Body? data.Message_Body:"",
  //                 Attachment1: data.Attachment1? data.Attachment1:"",
  //                 Attachment2: data.Attachment2? data.Attachment2:"",
  //                 Attachment3: data.Attachment3? data.Attachment3:"",
  //             });
  //         });

  //         hideLoader();
  //     } catch (error) {
  //         hideLoader();
  //         console.error("Error fetching data: ", error);
  //     }
  // };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  // const UploadAcknowledgementHandle = async (event) => {
  //   event.preventDefault();

  //   const formdata = new FormData();
  //   formdata.append("Message_Body", upload.Message_Body);
  //   formdata.append("Attachment1", upload.Attachment1);
  //   formdata.append("Attachment2", upload.Attachment2);
  //   formdata.append("Attachment3", upload.Attachment3);
  //   formdata.append("Customer_Id", updateData.Sr_No);
  //   formdata.append("Certificate_Name", updateData.Certificate_Name);

  //   const validationErrors = {};

  //   if (!upload.Message_Body.trim()) {
  //     validationErrors.Message_Body = "Message body field is required.";
  //   }

  //   if (Object.keys(validationErrors).length === 0) {
  //     showLoader();

  //     // Send PUT request
  //     await axios
  //       .put(`/uploadAcknowledgement/${updateData.Enquiry_Sr_No}`, formdata)
  //       .then((res) => {
  //         Swal.fire(
  //           "Success!",
  //           "Acknowledgement Uploaded Successfully.",
  //           "success"
  //         );
  //         handleClose();
  //         hideLoader();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         Swal.fire({
  //           title: "Error",
  //           text: "Failed to Upload. Please try again later.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //         hideLoader();
  //       });
  //   } else {
  //     setErrors(validationErrors);
  //   }
  // };

  // const editorConfiguration = {
  //     plugins: [CKFinder, ...ClassicEditor.builtinPlugins],
  //     ckfinder: {
  //         uploadUrl: `${apiUrl}/uploadCkEditor`
  //     },
  // };
  const UploadAcknowledgementHandle = async (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append("Message_Body", upload.Message_Body);
    formdata.append("Attachment1", upload.Attachment1);
    formdata.append("Attachment2", upload.Attachment2);
    formdata.append("Attachment3", upload.Attachment3);
    formdata.append("Application_Date", upload.Application_Date);
    formdata.append("Application_Number", upload.Application_Number);
    formdata.append("Username", upload.Username);
    formdata.append("Password", upload.Password);
    formdata.append("Link", upload.Link);
    formdata.append("Mobile_Number", upload.Mobile_Number);
    formdata.append("Customer_Id", updateData.Sr_No);
    formdata.append("Certificate_Name", updateData.Certificate_Name);

    const validationErrors = {};
    if (!upload.Message_Body.trim()) {
      validationErrors.Message_Body = "Message body field is required.";
    }

    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      try {
        await axios.put(
          `/uploadAcknowledgement/${updateData.Enquiry_Sr_No}`,
          formdata
        );
        Swal.fire(
          "Success!",
          "Acknowledgement Uploaded Successfully.",
          "success"
        );
        handleClose();
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Upload. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        hideLoader();
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Body>
        <h5>Upload Acknowledgement - {`(${updateData.Certificate_Name})`}</h5>
        <hr className="p-0 mb-2" />
        <form onSubmit={UploadAcknowledgementHandle}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="Message_Body" className="form-label">
                  Message Body<span style={{ color: "red" }}>*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={upload.Message_Body}
                  //config={editorConfiguration}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setErrors({ Message_Body: "" });
                    setUpload({
                      ...upload,
                      Message_Body: data,
                    });
                  }}
                />
                {errors && (
                  <p style={{ color: "red" }}>{errors.Message_Body}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Attachment1" className="form-label">
                  Application Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="Application-Date"
                  className="form-control"
                  value={upload.Application_Date}
                  onChange={(e) =>
                    setUpload({ ...upload, Application_Date: e.target.value })
                  }
                  placeholder="Date"
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Application-Number" className="form-label">
                  Application No.<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Attachment1"
                  className="form-control"
                  value={upload.Application_Number}
                  onChange={(e) =>
                    setUpload({ ...upload, Application_Number: e.target.value })
                  }
                  placeholder="Enter your Application Number..."
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Username" className="form-label">
                  Username<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={upload.Username}
                  onChange={(e) =>
                    setUpload({ ...upload, Username: e.target.value })
                  }
                  placeholder="Enter your Username..."
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Password" className="form-label">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Password"
                  className="form-control"
                  value={upload.Password}
                  onChange={(e) =>
                    setUpload({ ...upload, Password: e.target.value })
                  }
                  placeholder="Enter your password..."
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="Password" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="Link"
                  className="form-control"
                  value={upload.Link}
                  onChange={(e) =>
                    setUpload({ ...upload, Link: e.target.value })
                  }
                  placeholder="Enter the Link..."
                />
              </div>
            </div>
            <div className="col-md-6">
  <div className="mb-3">
    <label htmlFor="mobileNumber" className="form-label">
      OTP Mobile Number<span style={{ color: "red" }}>*</span>
    </label>
    <input
      type="tel"
      id="mobileNumber"
      className="form-control"
      placeholder="Enter your Mobile Number..."
      value={upload.Mobile_Number}
      onChange={(e) =>
        setUpload({ ...upload, Mobile_Number: e.target.value })
      }
      required
    />
  </div>
</div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment1" className="form-label">
                  Attachment1
                </label>
                <input
                  type="file"
                  id="Attachment1"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment1: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment2" className="form-label">
                  Attachment2
                </label>
                <input
                  type="file"
                  id="Attachment2"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment2: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment3" className="form-label">
                  Attachment3
                </label>
                <input
                  type="file"
                  id="Attachment3"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment3: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-2 col-sm-3 col-xs-3 col-3">
              <button type="submit" className="btn btn-md btn-primary ">
                Submit
              </button>
            </div>
          </div>
        </form>

        {loader}
      </Modal.Body>
    </Modal>
  );
};
export default UploadAcknowledgement;
