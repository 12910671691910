import * as React from "react";
import Select from "react-select";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import { formatDate } from '../../components/dateFormate'
import dayjs from "dayjs";



const ViewAllDuePayments = () => {
  const { userData } = useUser();
  
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
const [filter, setFilter] = useState({
  Customer_Id: "",
  Company_Name: "",
  Certificate: "",
});
useEffect(() => {
  if (userData && userData.Org_Id) {
    loadStaffData();
  }
}, [userData]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getAllDuePayments?Org_Id=${userData.Org_Id}`);
  
      // Check if result.data has the installments array
      if (result.data && Array.isArray(result.data.installments)) {
        setStaffData(result.data.installments); // Set staff data to the installments array
        setStaffDataa(result.data.installments); // Set another state to the installments array
        //console.log(result.data.installments[0]); // Log the first element of installments if available
      } else {
        console.warn("Unexpected data format:", result.data);
        setStaffData([]); // Set staff data to an empty array if format is unexpected
        setStaffDataa([]);
      }
  
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };
  

  const uniqueCompanyNames = [
    { label: "All", value: "" },
    ...[...new Set(dataa.map(row => row.Company_Name))].map(name => ({
      label: name,
      value: name,
    })),
  ];

  const uniqueCertificateNames = [
    { label: "All", value: "" },
    ...[...new Set(dataa.map(row => row.Certificate))].map(name => ({
      label: name,
      value: name,
    })),
  ];
  

  const handleFilterChange = (selectedOption) => {
    setFilter((prev) => ({
      ...prev,
      Company_Name: selectedOption ? selectedOption.value : "",

    }));
  };

  const handleCertificateChange = (selectedOption) => {
    setFilter((prev) => ({
      ...prev,
      Certificate: selectedOption ? selectedOption.value : "",

    }));
  };

  
  const applyFilter = () => {
    const { Company_Name, Certificate, fromDate, toDate } = filter;

    const filteredData = dataa.filter((row) => {
      const isCompanyMatch = !Company_Name || row.Company_Name.toLowerCase().includes(Company_Name.toLowerCase());
      const isCertificateMatch = !Certificate || row.Certificate?.toLowerCase().includes(Certificate.toLowerCase());
      const isDateInRange = (!fromDate || new Date(row.Invoice_Date) >= new Date(fromDate)) &&
                            (!toDate || new Date(row.Invoice_Date) <= new Date(toDate));

      return isCompanyMatch && isCertificateMatch && isDateInRange;
    });

    setStaffData(filteredData);
  };
  
  

  const columns = [
    
    {
      name: "Enquiry ID",
      selector: (row) => row.Enquiry_Id,
      width: "100px",
    },
    {
      name: "Customer ID",
      selector: (row) => row.CustomerId,
      width: "150px",
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "290px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name}

          </span>
        </LightTooltip>
      ),
      width: "290px",
    },
 
    {
      name: "Customer Name",
      selector: (row) => row.Customer_Name,
      width: "150px",
    },
   
    {
      name: "Certificate Name",
      selector: (row) => row.Certificate,
      width: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row.Remarks,
      width: "120px",
    },
    {
      name: "Due Date",
      selector: (row) => formatDate(row.Due_Date), // assuming you have a Due_Date field
      width: "120px",
    },
    {
      name: "Due Amount",
      selector: (row) => (row.Amount - row.Amount_Received).toFixed(2), // calculating Due Amount
      width: "120px",
    },
    
  ];
  
  

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));



  const DownloadPDF = () => {
    const doc = new jsPDF({
        orientation: 'landscape'
    });
    doc.text("Overdue Reports", 20, 10);

    // Updated columns to include new fields
    const columns = [
        "Enquiry ID",
        "Customer ID",
        "Company Name",
        "Customer Name",
        "Certificate Name",
        "Remarks",
        "Due Date",
        "Due Amount"
    ];

    // Update rows to include new fields from data
    const rows = data.map((row) => [
        row.Enquiry_Id,
        row.Customer_Id, // Make sure to include Customer ID
        row.Company_Name,
        row.Customer_Name,
        row.Certificate,
        row.Remarks,
        formatDate(row.Due_Date),
        (row.Amount - row.Amount_Received).toFixed(2) 
    ]);

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    doc.save("OverdueReports.pdf");
};



const DownloadExcel = () => {
  const columns = [
      "Enquiry ID",
      "Customer ID",
      "Company Name",
      "Customer Name",
      "Certificate Name",
      "Remarks",
      "Due Date",
      "Due Amount"
  ];
  
  // Initialize rows with columns
  const rows = [columns];
  
  data.forEach((row) => {
      rows.push([
          row.Enquiry_Id,             // Match the column order
          row.Customer_Id,            // Include Customer ID
          row.Company_Name,
          row.Customer_Name,
          row.Certificate,
          row.Remarks,
          formatDate(row.Due_Date),   // Format Due Date
          (row.Amount - row.Amount_Received).toFixed(2) // Calculate Due Amount
      ]);
  });

  console.log("excel " + rows);

  const workSheet = XLSX.utils.aoa_to_sheet(rows);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Overdue Reports");

  // Download the Excel file
  XLSX.writeFile(workBook, "OverdueReports.xlsx");
};





function handleFilter(event) {
  const filterValue = event.target.value.toLowerCase();
  const filteredData = dataa.filter((row) => {
      return (
          (row.Enquiry_Id && row.Enquiry_Id.toString().includes(filterValue)) ||
          (row.CustomerId && row.CustomerId.toString().includes(filterValue)) ||
          (row.Company_Name && row.Company_Name.toLowerCase().includes(filterValue)) ||
          (row.Customer_Name && row.Customer_Name.toLowerCase().includes(filterValue)) ||
          (row.Due_date && row.Due_date.toLowerCase().includes(filterValue)) ||
          (row.Due_Amount && row.Due_Amount.toString().includes(filterValue))
      );
  });
  setStaffData(filteredData);
}


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Overdue Reports</h4>
          </div>
          <div className="card">
            
          <div className="card-body" style={{paddingTop:"15px"}}>
              <div className="row justify-content-center mt-2">
              <div className="col-4">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <Select
                    id="companyName"
                    options={uniqueCompanyNames}
                    value={
                      filter.Company_Name
                        ? { label: filter.Company_Name, value: filter.Company_Name }
                        : null
                    }
                    onChange={handleFilterChange}
                    placeholder="Select Company"
                  />
                </div>

                <div className="col-4">
      <label htmlFor="certificateName" className="form-label">
        Certificate Name
      </label>
      <Select
  id="certificateName"
  options={uniqueCertificateNames}
  value={
    filter.Certificate
      ? { label: filter.Certificate, value: filter.Certificate }
      : null
  }
  onChange={handleCertificateChange}
  placeholder="Select Certificate..."
/>

    </div>

                
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-2">
                <button
                  className="btn btn-sm btn-primary"
                  style={{ width: "100%" }}
                  onClick={applyFilter}
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="card-body">
          
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default ViewAllDuePayments;
